<template>
    <div class="contact">
        <div class="contactBox">
            <div v-if="isSdkReady" @click="onlineKF">
                <img class="icon" src="@/assets/KF.png" alt="">
                <div class="slide-title">在线客服</div>
            </div>
            <span class="line" v-if="isSdkReady"></span>
            <div>
                <el-popover
                    placement="left"
                    trigger="hover">
                    <div class="content"><img class="wecomCode" src="@/assets/wecomCode.jpg" alt=""></div>
                    <div slot="reference">
                        <img class="icon" src="@/assets/weixinicon.png" alt="">
                        <div class="slide-title">微信咨询</div>                        
                    </div>
                </el-popover>
            </div>
            <span class="line"></span>
            <div>
                <el-popover
                    placement="left"
                    trigger="hover">
                    <div class="content">
                        <a href="tel:4000128088">联系电话：400-012-8088</a>
                    </div>
                    <div slot="reference">
                        <img class="icon" src="@/assets/callmeicon.png" alt="">
                        <div class="slide-title">电话咨询</div>                        
                    </div>
                </el-popover>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import YSF from '@neysf/qiyu-web-sdk';
export default {
  name: "contact",
  data(){
    return{
        isSdkReady:false,
        ysf:'',
    }
  },
  mounted(){
    let _this=this;
    YSF.init('0697002b98e8aac6201461fbbe307903', {
        templateId: 6676777
    }).then(ysf => {
        _this.ysf=ysf;
        ysf('onready', ()=> {
            _this.isSdkReady = true;
        })
    }).catch(error => {
        console.log('sdk加载失败---', error);
    });
  },
  methods:{
    onlineKF(){
        if(this.isSdkReady) {
            // location.href = ysf('url',{
            //     'templateId':6676777
            // });
            var left = (screen.width / 2) - (842 / 2);
            var top = (screen.height / 2) - (632 / 2);
            if(screen.width>=1200){
                window.open(this.ysf('url',{
                    'templateId':6676777
                }), '_blank', `width=842,height=632,top=${top},left=${left},resizable=yes`);
            }else{
                this.ysf('open',{
                    'templateId':6676777
                });
            }
        }
    }
  }
}
</script>
<style scoped lang="scss">
a:-webkit-any-link{
    color: black!important;
}
.wecomCode{
    width: 2.6rem;
}
.content{
    color: #000;
    font-size: .22rem;
}
.contact{
    color: #fff;
    .contactBox{
        background-color: #C33128;
        border-radius: .05rem;
        padding: 8px 4px;
        position: fixed;
        right: .2rem;
        bottom:20%;
        z-index: 2037;
        border: 1px solid #fff3;
        text-align: center;
        line-height: 1.5em;
        .icon{
            height: 30px;
            margin-bottom: 6px;
        }
        .line{
            display: inline-block;
            margin: .1rem 0;
            width: 100%; 
            height: .02rem; 
            background-color: rgba(255, 255, 255, .2);
        }
        .slide-title{
            font-size: 14px;
        }
    }
}

@media (min-width:1920px){
    .contactBox{
        right: calc((100vw - 1920px) / 2 + .2rem)!important;
    }
}

@media (max-width:991px){
.wecomCode{
    width: 3rem;
}
.content{
    font-size: .26rem;
}
.contact{
    .contactBox{
        padding: .2rem .1rem;
        line-height: 1em;
        .icon{
            height: .6rem;
        }
        .slide-title{
            font-size: .23rem;
        }
    }
}

}
</style>